<template lang="html">
  <v-form ref="form" lazy-validation autocomplete="off">
    <v-card class="elevation-1">
      <v-card-title>
        <h2 class="headline mb-0">{{ $tc('label.cliente_pequeno_porte', 1) }}</h2>
      </v-card-title>
      <v-container grid-list-xl fluid>
        <metadados-container-layout
          :layout-class="layoutClass"
          :input-layout="inputLayout"
          :objeto="clienteIndireto"
          :formulario-filtros="false"
          :somenteLeitura="somenteLeitura"
          :novoCadastro="true"
          @MetadadosContainerLayout_abrirCadastro="abrirCadastro"
          ref="container">
        <v-col cols="12" sm="12" md="4" slot="antes">
          <v-autocomplete
            id="campo_cliente"
            name="campo_cliente"
            v-model="clienteIndireto.idCliente"
            :disabled="somenteLeitura"
            :items="clientes"
            :rules="[rules.required]"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="desCnpjNome"
            item-value="id"
            :label="`${$tc('label.distribuidor', 1)} *`"
            clearable
            :search-input.sync="buscaListaClientes"
            @click.native="buscarClientes">
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="4" slot="antes">
          <v-autocomplete
            id="campo_estado"
            name="campo_estado"
            v-model="clienteIndireto.idEstado"
            :disabled="somenteLeitura"
            :items="estados"
            :rules="[rules.required]"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="nomUf"
            item-value="id"
            :label="`${$tc('label.estado', 1)} *`"
            clearable
            @change="clearCidades"
            @click.native="buscarEstados">
          </v-autocomplete>
        </v-col>
         <v-col cols="12" sm="6" md="4" slot="antes">
          <v-autocomplete
            id="campo_cidade"
            name="campo_cidade"
            v-model="clienteIndireto.idCidade"
            :disabled="somenteLeitura"
            :items="cidades"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="nomCidade"
            item-value="id"
            :label="`${$tc('label.cidade', 1)} *`"
            clearable
            :rules="[rules.required]"
            :search-input.sync="buscaListaCidades"
            @click.native="buscarCidades">
          </v-autocomplete>
         </v-col>
         <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_nome"
              name="campo_nome"
              v-model="clienteIndireto.nomClienteIndireto"
              :label="`${$tc('label.razao_social_cliente', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              :counter="50"
              maxlength="50"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_cnpj"
              name="campo_cnpj"
              v-model="clienteIndireto.desCnpj"
              :label="`${$tc('label.cnpj_cpf', 1)} *`"
              :rules="[rules.required, rules.documento]"
              :disabled="somenteLeitura"
              :maxlength="18"
              v-mask="['###.###.###-##','##.###.###/####-##']"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-switch
              color="primary"
              :label="$t('label.segmentacao_mantida')"
              v-model="clienteIndireto.segmentacaoMantida"
              :disabled="somenteLeitura"
              class="mt-3">
            </v-switch>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
          <v-menu
              ref="data_treinamento_segmentacao"
              :close-on-content-click="true"
              v-model="menuDataTreinamentoSegmentacao"
              id="data_treinamento_segmentacao"
              name="data_treinamento_segmentacao"
              :nudge-right="40"
              :disabled="somenteLeitura"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  class="ClienteForm-data__text"
                  :return-masked-value="true"
                  v-mask="'##-##-####'"
                  v-model="dataTreinamentoSegmentacaoFormatada"
                  :label="`${$t('label.data_treinamento_segmentacao')}`"
                  prepend-icon="event">
                </v-text-field>
              </template>
              <v-date-picker locale="pt-br" color="primary" v-model="dataTreinamentoSegmentacao" @input="menuDataTreinamentoSegmentacao = false"></v-date-picker>
            </v-menu>
          </v-col>
        </metadados-container-layout>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="btn_cancelar_novo_cliente_indireto" @click.native="openCancelarDialog">{{ $t('label.cancelar') }}</v-btn>
        <v-btn id="btn_salvar_novo_cliente_indireto" v-if="!somenteLeitura" @click.native="openSaveDialog" color="primary">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
    </v-card>

    <confirm
      ref="confirmDialog"
      :message="$t('message.deseja_salvar', { text: this.$tc('label.cliente_pequeno_porte', 1).toLowerCase() })"
      @agree="salvar">
    </confirm>

    <confirm
      ref="cancelarDialog" :title="$t('title.atencao')"
      :message="$t('message.tem_certeza_cancelar')"
      @agree="cancelar">
    </confirm>
  </v-form>
</template>
<script>

import { formatDateDDMMYYYY, parseDateYYYYMMDD } from '@/produto/common/functions/date-utils';
import { skipLoading } from '../../produto/common/functions/loading';
import MetadadosContainerLayout from '../../produto/shared-components/metadados/MetadadosContainerLayout';
import Confirm from '../../produto/shared-components/vuetify/dialog/Confirm';

export default {
  name: 'ClienteIndiretoForm',
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MetadadosContainerLayout,
    Confirm,
  },
  data() {
    return {

      resourceClienteIndireto: this.$api.clienteIndireto(this.$resource),

      buscaListaCidades: null,
      buscaListaClientes: null,
      menuDataTreinamentoSegmentacao: false,

      estados: [],
      cidades: [],
      clientes: [],
      clienteIndireto: {
        nomClienteIndireto: null,
        desCnpj: null,
        id: null,
        dtaTreinamentoSegmentacao: null,
      },
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: false,
        md4: true,
        md6: false,
        md9: false,
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        documento: (value) => {
          if (value) {
            const valor = value.replace(/\D+/g, '');
            if (valor.length < 11 && valor.length !== 11) {
              return this.$t('message.cpf_tamanho');
            }
            if (valor.length > 11 && valor.length !== 14) {
              return this.$t('message.cnpj_tamanho');
            }
            return !!value;
          }
          return !!value;
        },
      },
    };
  },
  computed: {
    dataTreinamentoSegmentacaoFormatada: {
      get() {
        return formatDateDDMMYYYY(this.clienteIndireto.dtaTreinamentoSegmentacao);
      },
      set(newValue) {
        this.clienteIndireto.dtaTreinamentoSegmentacao = parseDateYYYYMMDD(newValue);
      },
    },
    dataTreinamentoSegmentacao: {
      get() {
        return this.clienteIndireto.dtaTreinamentoSegmentacao;
      },
      set(newValue) {
        this.clienteIndireto.dtaTreinamentoSegmentacao = newValue;
      },
    },
  },
  watch: {
    buscaListaCidades(val) {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        if (val != null) this.buscarCidades(val);
      }, 500);
    },
    buscaListaClientes(val) {
      if (this.clienteIndireto && this.clienteIndireto.idCliente) {
        const selecao = this.clientes
          .filter((item) => item.id === this.clienteIndireto.idCliente);
        if (selecao.length > 0 && selecao[0].desCnpjNome === val) {
          return;
        }
      }
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        if (val != null) this.buscarClientes(val);
      }, 500);
    },
  },
  methods: {
    carregarCliente() {
      this.buscarEstados();
      const { id } = this.$route.params;
      skipLoading();
      this.resourceClienteIndireto.buscar({ id })
        .then((res) => {
          this.clienteIndireto = { ...this.clienteIndireto, ...res.body };
          if (this.clienteIndireto.idEstado) {
            this.buscarCidades();
          }
          if (this.clienteIndireto.idCliente) {
            this.buscarClientes();
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarEstados() {
      return this.resourceClienteIndireto.buscarEstadosAtivos()
        .then((res) => {
          this.estados = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarCidades(autocomplete) {
      const params = {
        codEstado: this.clienteIndireto.idEstado,
        codCidade: this.clienteIndireto.idCidade ? this.clienteIndireto.idCidade : null,
        autocomplete,
      };
      return this.resourceClienteIndireto.buscarCidadesPorEstado(params)
        .then((res) => {
          this.cidades = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    clearCidades() {
      this.clienteIndireto.idCidade = null;
      this.cidades = [];
    },
    buscarClientes(autocomplete = null) {
      if (typeof autocomplete !== 'string') {
        autocomplete = null;
      }
      const param = {
        idCliente: this.clienteIndireto.idCliente,
        autocomplete,
      };
      return this.resourceClienteIndireto.buscarClientes(param)
        .then((res) => {
          this.clientes = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    openCancelarDialog() {
      if (this.somenteLeitura) {
        this.cancelar();
        return;
      }
      this.$refs.cancelarDialog.open();
    },
    openSaveDialog() {
      if (!this.$refs.form.validate()) return;
      this.$refs.confirmDialog.open();
    },
    salvar() {
      const objArmazenamento = {
        ...this.clienteIndireto,
        ...this.$refs.container.getValoresDependencias(),
      };
      objArmazenamento.desCnpj = objArmazenamento.desCnpj.replace(/\D+/g, '');
      if (this.clienteIndireto.id) {
        this.atualizar(objArmazenamento);
      } else {
        this.gravar(objArmazenamento);
      }
    },
    gravar(obj) {
      this.resourceClienteIndireto.gravar(obj)
        .then(() => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          this.clienteIndireto = {};
          this.$router.push({ name: this.$route.params.from || 'cliente-indireto' });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    atualizar(obj) {
      this.resourceClienteIndireto.atualizar(obj)
        .then(() => {
          this.$toast(this.$t('message.atualizado_com_sucesso'));
          this.clienteIndireto = {};
          this.$router.push({ name: this.$route.params.from || 'cliente-indireto' });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    cancelar() {
      this.$router.push({ name: this.$route.params.from || 'cliente-indireto' });
    },
    abrirCadastro(metadados) {
      this.novaExtensao = {};
      this.novaExtensao.indAtivo = true;
      this.novaExtensao.idNivelExtensao = metadados.idNivelExtensao;
      this.novaExtensao.metadados = metadados;
      this.$refs.fornecedorNovaExtensao.setNivel(metadados.idNivelExtensao);
      this.dialogCadastroExtensao = true;
    },
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.carregarCliente();
    }
  },
};
</script>
